import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { StorageFacade } from 'storage-store-facade/storage.facade';
import { Observable, from, throwError, of } from 'rxjs';
import { first, map, catchError, concatMap, switchMap } from 'rxjs/operators';
import { Auth } from 'app/models';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import * as dayjs from 'dayjs';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  constructor(private storage: StorageFacade) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.storage.get('auth')).pipe(
      first(),
      switchMap((auth: Auth) => {
        let refreshRequest = request;
        if (auth) {
          const tokenExpiresIn = dayjs.unix(auth?.expiresAt).diff(dayjs(), 'minutes');

          // adds the refresh-access-token header to request a token refresh if the current token would expire within the next 5 minutes
          if (tokenExpiresIn < 5) {
            refreshRequest = request.clone({
              headers: request.headers.append('refresh-access-token', 'true'),
            });
          }
        }

        return next.handle(refreshRequest).pipe(
          concatMap((event: HttpEvent<any>) =>
            event instanceof HttpResponse ? this.refreshToken(event) : of(event)
          ),
          map((event: HttpEvent<any>) => {
            return event;
          }),
          catchError((error: HttpErrorResponse) => {
            return throwError(() => error);
          })
        );
      })
    );
  }

  private refreshToken(response: HttpResponse<any>): Observable<HttpEvent<any>> {
    const authorizationHeader = response.headers.get('authorization')?.trim();

    if (authorizationHeader) {
      return from(this.storage.get('auth')).pipe(
        concatMap(({ headers, data }: Auth) => {
          const jwtToken = authorizationHeader?.replace('Bearer ', '');
          // gets the expiration timestamp from the decodes JWT
          const expiresAt = jwtToken ? jwtDecode<JwtPayload>(jwtToken)?.exp : null;

          return from(
            this.storage.set('auth', {
              headers: {
                ...headers,
                ['authorization']: authorizationHeader,
              },
              data,
              expiresAt,
            })
          );
        }),
        map(() => {
          return response;
        })
      );
    } else {
      return of(response);
    }
  }
}
