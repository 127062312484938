import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, from, throwError } from 'rxjs';
import { first, map, switchMap, catchError } from 'rxjs/operators';
import { getDeviceInfoHeaders, DeviceInfoHeaders } from './headers';

@Injectable()
export class DeviceInfoInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(getDeviceInfoHeaders()).pipe<any, HttpEvent<any>>(
      first(),
      switchMap((deviceInfoHeaders: DeviceInfoHeaders) => {
        let req: HttpRequest<any>;
        if (deviceInfoHeaders) {
          const requestHeaders = request.headers.keys.length !== 0 ? request.headers : {};
          const httpHeaders = new HttpHeaders({
            ...deviceInfoHeaders,
            ...requestHeaders,
          });
          req = request.clone({
            headers: httpHeaders,
          });
        } else {
          req = request;
        }
        return next.handle(req).pipe(
          map((event: HttpEvent<any>) => {
            return event;
          }),
          catchError((error: HttpErrorResponse) => {
            return throwError(error);
          })
        );
      })
    );
  }
}
