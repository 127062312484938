import { Environment, version } from './constants';

export const environment: Environment = {
  version: `${version} Staging`,
  production: true,
  reAuthentication: true,
  apiUrl: 'https://carecenter.staging.procarement.com/api/v1',
  kardiaCredentials: {
    baseUrl: 'https://kardia-auth-server.staging.procarement.com/',
    partnerId: 'JACkB7w88h5Cjxl78A09cgeumju6ygkg',
    teamId: '3gdAfg6bUH7t5Lo9N6tJcgeumiybl1yw',
    environment: 'STAGING',
  },
  oneSignalAppId: '503dc517-5536-4602-9244-91472ff5f246',
  appSignalKey: 'f45a3a49-7b20-42cd-b857-a78a77e11ad1',

  channel: 'Staging',
  e2e: false,
};
