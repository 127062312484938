import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { StorageFacade } from 'storage-store-facade/storage.facade';
import { Observable, from, throwError, combineLatest, EMPTY } from 'rxjs';
import { first, map, switchMap, catchError } from 'rxjs/operators';
import { Auth } from 'app/models';
import { getDeviceInfoHeaders, DeviceInfoHeaders, getAuthHeaders } from './headers';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private storage: StorageFacade) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return combineLatest([from(this.storage.get('auth')), from(getDeviceInfoHeaders())]).pipe<
      any,
      HttpEvent<any>
    >(
      first(),
      switchMap(([auth, deviceInfoHeaders]: [Auth, DeviceInfoHeaders]) => {
        let req: HttpRequest<any>;
        if (auth) {
          const headers = auth.headers;
          const requestHeaders = request.headers.keys.length !== 0 ? request.headers : {};
          const httpHeaders = new HttpHeaders({
            ...getAuthHeaders(request, headers),
            ...deviceInfoHeaders,
            ...requestHeaders,
          });
          req = request.clone({
            headers: httpHeaders,
          });
        } else {
          return EMPTY;
        }
        return next.handle(req).pipe(
          map((event: HttpEvent<any>) => {
            return event;
          }),
          catchError((error: HttpErrorResponse) => {
            return throwError(error);
          })
        );
      })
    );
  }
}
