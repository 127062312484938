import { Action, createAction, props } from '@ngrx/store';
import { Document } from 'app/models';

export const loadDocuments = createAction('[Document/API] Load Documents');

export const loadDocumentsSuccess = createAction(
  '[Document/API] Load Documents Success',
  props<{ documents: Document[] }>()
);

export const loadDocumentsFailure = createAction(
  '[Document/API] Load Documents Failure',
  props<{ error: any }>()
);

export const loadDocumentsComplete = createAction(
  '[Document/API] Load Documents Complete',
  props<{ action: Action }>()
);

export const loadDocument = createAction('[Document/API] Load Document', props<{ id: number }>());

export const loadDocumentSuccess = createAction(
  '[Document/API] Load Document Success',
  props<{ document: Document }>()
);

export const loadDocumentFailure = createAction(
  '[Document/API] Load Document Failure',
  props<{ error: any }>()
);

export const deleteDocument = createAction(
  '[Document/API] Delete Document',
  props<{ id: number }>()
);

export const deleteDocumentSuccess = createAction(
  '[Document/API] Delete Document Success',
  props<{ id: number }>()
);

export const deleteDocumentFailure = createAction(
  '[Document/API] Delete Document Failure',
  props<{ error: any }>()
);

export const deleteDocumentFromDatabase = createAction(
  '[Document/API] Delete Document From Database',
  props<{ id: number }>()
);

export const deleteDocumentFromDatabaseSuccess = createAction(
  '[Document/API] Delete Document From Database Success'
);

export const deleteDocumentFromStore = createAction(
  '[Document/API] Delete Document From Store',
  props<{ id: number }>()
);

export const loadDocumentByRouteId = createAction('[Document/API] Load Documents By Route Id');
