import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, catchError, switchMap, mergeMap, withLatestFrom } from 'rxjs/operators';
import { catchComplete } from 'app/utils';
import { ApiService } from 'store/api/api.service';
import * as MeasurementActions from 'store/measurements-store/measurements.actions';
import * as DocumentActions from './document.actions';
import * as fromRouterSelectors from 'store/router-store/router.selectors';
import * as fromDocuments from './document.reducer';
import { Store, select } from '@ngrx/store';

@Injectable()
export class DocumentEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromDocuments.State | fromRouterSelectors.State>,
    private apiService: ApiService
  ) {}

  loadDocuments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.loadDocuments, MeasurementActions.addMeasurementsSuccess),
      mergeMap((action) =>
        this.apiService.getDocuments(action.type === DocumentActions.loadDocuments.type).pipe(
          map((data) => DocumentActions.loadDocumentsSuccess({ documents: data.results })),
          catchError((error) => of(DocumentActions.loadDocumentsFailure({ error }))),
          catchComplete(() => of(DocumentActions.loadDocumentsComplete({ action })))
        )
      )
    );
  });

  loadDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.loadDocument),
      switchMap((action) =>
        this.apiService.getDocument(action.id).pipe(
          map((data) => DocumentActions.loadDocumentSuccess({ document: data })),
          catchError((error) => of(DocumentActions.loadDocumentFailure({ error })))
        )
      )
    );
  });

  loadDocumentByRouteId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.loadDocumentByRouteId),
      withLatestFrom(this.store.pipe(select(fromRouterSelectors.selectRouteParam('id')))),
      map(([, routeId]) => DocumentActions.loadDocument({ id: +routeId }))
    );
  });

  deleteDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActions.deleteDocument, DocumentActions.deleteDocumentFromDatabase),
      mergeMap((action) =>
        this.apiService.deleteDocument(action.id).pipe(
          map(() =>
            action.type === DocumentActions.deleteDocument.type
              ? DocumentActions.deleteDocumentSuccess({ id: action.id })
              : DocumentActions.deleteDocumentFromDatabaseSuccess()
          ),
          catchError((error) => of(DocumentActions.deleteDocumentFailure({ error })))
        )
      )
    );
  });
}
